﻿body {
    padding-top: 50px;
}

.main-container {
    padding-top: 20px;
}

.footer {
    padding-top: 40px;
    padding-bottom: 30px;
    margin-top: 100px;
    color: #777;
    text-align: center;
    border-top: 1px solid #e5e5e5;
}

.github-fork-ribbon.right-top:before {
    background-color: #f80;
}

@media (min-width: 768px) {
    .footer {
        text-align: left;
    }
    .footer p {
        margin-bottom: 0;
    }
}

@-webkit-viewport   { width: device-width; }
@-moz-viewport      { width: device-width; }
@-ms-viewport       { width: device-width; }
@-o-viewport        { width: device-width; }
@viewport           { width: device-width; }

